import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Bar = styled.div`
  width: 100%;
  height: 8px;
  margin-bottom: 0.5em;
  background: linear-gradient(120deg, #233774, #6d91e8);
`;

const FlashBar = ({ color }) => <Bar color={color} />;

FlashBar.propTypes = {
  color: PropTypes.string,
};

FlashBar.defaultProps = {
  color: '',
};

export default FlashBar;
