import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isArray } from 'lodash';
import queryString from 'query-string';

const queryStringOpts = {
  arrayFormat: 'comma',
};

const useUrlFilters = () => {
  const history = useHistory();
  const loc = useLocation();
  const params = queryString.parse(loc.search, queryStringOpts);

  if (!isArray(params.retailers) && params.retailers !== undefined) {
    params.retailers = [params.retailers];
  }

  if (!isArray(params.searchTerms) && params.searchTerms !== undefined) {
    params.searchTerms = [params.searchTerms];
  }

  const [filters, setFiltersState] = useState(params);

  const setFilters = (newFilters = {}) => {
    setFiltersState(newFilters);
    const serialised = queryString.stringify({ ...filters, ...newFilters }, queryStringOpts);
    history.replace({ search: serialised });
  };

  return {
    urlFilters: filters,
    setUrlFilters: setFilters,
  };
};

export default useUrlFilters;
