import React from 'react';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    color: (props) => props.color,
    background: (props) => props.background,
    width: '100%',
    borderRadius: 5,
    '&:hover': {
      color: (props) => props.color,
      background: (props) => props.background,
    },
    '&:active': {
      color: (props) => props.color,
      background: (props) => props.background,
    },
    '&:focus': {
      color: (props) => props.color,
      background: (props) => props.background,
    },
  },
});

const PromoBar = ({ type, onClick, text }) => {
  let background;
  let color;

  switch (type) {
    case 'MULTI_BUY':
      background = '#6a4385';
      color = 'white';
      break;
    case 'PRICE_CUT':
      background = '#e6c959';
      break;
    case 'UNCLASSIFIED':
    default:
      background = '#ccc';
      break;
  }

  const classes = useStyles({ background, color });

  if (type === null || type === undefined) {
    return null;
  }

  return (
    <Chip
      classes={{
        root: classes.root,
      }}
      label={text}
      onClick={onClick}
      data-component="PromoBar"
    />
  );
};

PromoBar.propTypes = {
  type: PropTypes.oneOf(['MULTI_BUY', 'PRICE_CUT', 'UNCLASSIFIED']),
  onClick: PropTypes.func,
  text: PropTypes.string,
};

PromoBar.defaultProps = {
  onClick: () => {},
  text: '',
  type: null,
};

export default PromoBar;
