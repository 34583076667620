import React, { useContext } from 'react';
import { get } from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { context } from '../store';
import Layout from './Layout';
import UserService from '../services/UserService';


const UserSettings = () => {
  const { state } = useContext(context);

  const handleDelete = () => {
    const input = prompt('This action cannot be undone.\nType your email address to confirm.');
    if (input === UserService.currentUser().email) {
      UserService.destroy();
    }
  };

  return (
    <Container maxWidth="sm">
      <TableContainer component={Paper}>
        <Table aria-label="user settings table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Email
              </TableCell>
              <TableCell align="left">
                {get(state, 'user.email')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Display Name
              </TableCell>
              <TableCell align="left">
                {get(state, 'user.displayName')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Created
              </TableCell>
              <TableCell align="left">
                {get(state, 'user.metadata.creationTime')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Last Login
              </TableCell>
              <TableCell align="left">
                {get(state, 'user.metadata.lastSignInTime')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Delete Account
              </TableCell>
              <TableCell align="left">
                <Button variant="contained" color="secondary" onClick={handleDelete}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Layout({ Content: UserSettings });
