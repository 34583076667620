import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { scaleTime } from 'd3-scale';
import theme from '../theme';

const MiniTimeline = ({ data }) => {
  const [events, setEvents] = useState([]);

  const selectPromoColor = (type) => {
    switch (type) {
      case 'MULTI_BUY':
        return '#6a4385';
      case 'PRICE_CUT':
        return '#e6c959';
      case 'UNCLASSIFIED':
      default:
        return '#ccc';
    }
  };

  useEffect(() => {
    if (data) {
      const xScale = scaleTime()
        .domain([moment().startOf('day').subtract(12, 'weeks').toDate(), moment().startOf('day').toDate()]).range([30, 290]);

      const dataPoints = () => (
        data.map((item) => (
          <ellipse
            data-testid="eventBar"
            className="eventBar"
            key={item.timestamp}
            cx={xScale(moment(item.timestamp).startOf('day').day('Wednesday').toDate())}
            cy={15}
            rx={20}
            ry={10}
            fill={selectPromoColor(item.promotionType)}
          >
            <title>{moment(item.timestamp).startOf('day').toDate().toDateString()}</title>
          </ellipse>
        ))
      );
      setEvents(dataPoints(data));
    }
  }, [data]);


  return (
    <svg
      className="d3-component"
      viewBox="0 0 320 34"
    >
      {events}
      <rect
        y={0}
        height={30}
        width={320}
        fill="transparent"
        stroke="white"
        strokeWidth="14"
      />
      <rect
        x={2}
        y={2}
        height={26}
        width={315}
        fill="transparent"
        stroke={theme.efTheme.palette.efGrey.main}
        strokeWidth="1"
        rx={18}
        ry={15}
      />
    </svg>
  );
};


MiniTimeline.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.object,
  ),
};
MiniTimeline.defaultProps = {
  data: [],
};

export default MiniTimeline;
