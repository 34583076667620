import React, { useContext } from 'react';
import Header from '../components/Header';
import { context } from '../store';

const Layout = (components) => {
  const { Content } = components;

  const Component = () => {
    const { state } = useContext(context);

    return (
      <div>
        <Header user={state.user} />
        <main>
          <Content />
        </main>
      </div>
    );
  };

  Component.displayName = 'Layout';

  return Component;
};

export default Layout;
