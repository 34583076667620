import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputField from '../components/InputField';

import UserService from '../services/UserService';

const useStyles = makeStyles(() => ({
  errorbox: {
    border: '2px solid red',
    borderRadius: 4,
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const ForgotPasswordForm = () => {
  const style = useStyles();

  const [resetError, setResetError] = useState(false);
  const [resetDone, setResetDone] = useState(false);
  const [needToReset, setNeedToReset] = useState(true);
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await UserService.sendPasswordResetEmail(email);
      setResetDone(true);
      setNeedToReset(false);
      setResetError(false);
    } catch (e) {
      setResetError(true);
    }
  };

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className={style.resetpassword}>
      {
        needToReset
        && (
          <div>
            <form data-testid="forgotten-password-form" className={`${style.form}`} autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing="2">
                {
                resetError
                && (
                  <Grid item xs="12">
                    <div className={style.errorbox}>
                      <p>We couldn&apos;t find your account with that information</p>
                    </div>
                  </Grid>
                )
              }
                <Grid item xs="12">
                  <InputField
                    id="email"
                    className={style.resetInput}
                    required
                    errorMessage=""
                    placeholder="Email"
                    type="email"
                    onChange={handleInputChange}
                    value={email}
                  />
                </Grid>

                <Grid item xs="12">
                  <Button
                    className={style.loginSubmit}
                    variant="contained"
                    disableElevation
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        )
      }
      {
        resetDone
        && (
          <div>
            <h2>Check your email</h2>
            <p>
              We&apos;ve sent an email to
              {' '}
              <strong>{email}</strong>
            </p>
            <p>Click the link in the email to reset your password.</p>
          </div>
        )
      }
    </div>
  );
};

export default ForgotPasswordForm;
