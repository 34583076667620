import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import {
  Typography,
} from '@material-ui/core';
import SignUpForm from '../forms/SignUpForm';
import Layout from './Layout';
import FormWrapper from './FormWrapper';

const SignUp = ({ returnPath }) => {
  const [success, setSuccess] = useState(false);

  const handleSignin = () => {
    setSuccess(true);
  };

  if (success) {
    return (
      <Redirect to={returnPath} />
    );
  }

  return (
    <FormWrapper>
      <Typography variant="h4" component="h2" align="center">
        Sign Up
      </Typography>
      <SignUpForm onSignUp={handleSignin} />
    </FormWrapper>
  );
};

SignUp.propTypes = {
  returnPath: PropTypes.string,
};

SignUp.defaultProps = {
  returnPath: '/app',
};

export default Layout({ Content: SignUp });
