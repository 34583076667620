import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import RetailerCardHeader from './RetailerCardHeader';
import MiniTimeline from './MiniTimeline';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    overflow: 'visible',
    margin: theme.spacing(3),
  },
  main: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
  },
  media: {
    height: '5rem',
    width: '5rem',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(-1),
  },
  name: {
    marginBottom: theme.spacing(1),
    fontSize: '1rem',
  },
}));

const ProductCard = ({ product, handlePromoClick }) => {
  const classes = useStyles();


  return (
    <Card className={classes.root} raised>
      <CardActionArea onClick={handlePromoClick} onKeyPress={handlePromoClick} role="button" tabIndex={0}>
        <RetailerCardHeader retailer={product.retailer} />
        <CardContent>
          <div className={classes.main}>
            <div className={classes.media}>
              <CardMedia
                className={classes.media}
                image={
                  Array.isArray(product.imageURL)
                    ? product.imageURL[0]
                    : product.imageURL
                }
                title={product.name}
              />
            </div>
            <div>
              <div className={classes.name}>
                <span>{product.name}</span>
              </div>
            </div>
          </div>

          <Typography variant="body2" color="textSecondary" component="p">
            Last 12 weeks:
          </Typography>
          <div className={classes.timeline}>
            <MiniTimeline data={product.promotions} />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    retailer: PropTypes.string,
    imageURL: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    promotions: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  handlePromoClick: PropTypes.func,
};
ProductCard.defaultProps = {
  handlePromoClick: () => {},
};

export default ProductCard;
