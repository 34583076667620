import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    padding: '0 2px',
    width: '85%',
    wordWrap: 'break-word',
  },
  token: {
    fontSize: (props) => props.fontSize,
    display: 'inline-block',
    padding: '0 2px',
    borderRadius: 3,
    '&:hover, &:focus, &:active': {
      cursor: 'pointer',
      background: '#eaeaea',
      outline: 0,
    },
  },
});

const WordSelector = ({ text, onClick, fontSize }) => {
  const props = { fontSize };
  const styles = useStyles(props);

  const tokens = text.split(' ').map((token, idx) => ({
    value: token,
    id: `${token}_${idx}`,
  }));

  return (
    <div className={styles.container}>
      {tokens.map((token, idx) => (
        <span
          className={styles.token}
          data-component="WordSelector"
          role="button"
          tabIndex={idx}
          key={token.id}
          onFocus={() => {}}
          onKeyDown={() => { onClick(token.value); }}
          onClick={() => { onClick(token.value); }}
        >
          {token.value}
          {' '}
        </span>
      ))}
    </div>
  );
};

WordSelector.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.string,
  onClick: PropTypes.func,
};

WordSelector.defaultProps = {
  text: '',
  fontSize: '1em',
  onClick: () => {},
};

export default WordSelector;
