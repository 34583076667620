import { database } from './firebase';

const getUserRef = (key) => database.ref(`user/${key}`);

const getUser = async (user) => {
  try {
    const ref = await getUserRef(user.uid).once('value');
    return ref.val();
  } catch (error) {
    throw new Error('Failed to retrieve user data');
  }
};

const createUser = async (user, data) => {
  const ref = getUserRef(user.uid);
  await ref.set(data);
};

const updateUser = (user, data) => getUserRef(user.uid).update(data);

const removeUser = async (user) => {
  try {
    if (user) await getUserRef(user.uid).remove();
  } catch (error) {
    throw new Error('Failed to delete user');
  }
};

const storage = {
  getUser,
  updateUser,
  removeUser,
  createUser,
};

export default storage;
