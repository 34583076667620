export default {
  api: {
    host: process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8080',
    paths: {
      users: '/api/users',
      usersLogin: '/api/users/login',
      usersLoginGoogle: '/api/users/login/google',
      usersResetPassword: '/api/users/reset_password',
      usersSentResetPassword: '/api/users/send_reset_password',
      usersValidateToken: '/api/users/validate',
      promotions: '/api/promotions',
    },
    promotions: {
      tableResults: 25,
    },
  },
};
