import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Paper,
} from '@material-ui/core';

const FormWrapper = ({ children }) => (
  <Container maxWidth="sm">
    <Paper style={{ padding: 16 }}>
      {children}
    </Paper>
  </Container>
);

FormWrapper.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

FormWrapper.defaultProps = {
  children: null,
};

export default FormWrapper;
