/**
 * UserService provides an object for managing the current user and related docs
 */

import {
  auth,
  database,
  AuthProviders,
} from './firebase';

import track from './tracking';
import storage from './storage';

const MAX_SEARCH_HISTORY = 1000;

const checkAuthStatus = () => {
  if (!auth.currentUser) {
    throw new Error('User unauthenticated');
  }
};

export const defaultRetailers = ['Amazon-Italy', 'Amazon-Pantry-IT', 'Esselunga-IT', 'Coop-IT', 'Carrefour-IT', 'Zooplus-IT', 'Media-World-IT'];

const initialUserData = {
  firstName: '',
  lastName: '',
  successfulSearches: [],
  searchHistory: [
    {
      searchTerms: [],
      retailers: defaultRetailers,
      timestamp: new Date().toString(),
    },
  ],
  retailers: defaultRetailers,
  successfulSearchCount: 0,
  searchCount: 0,
  marketingConsent: true,
  onboarding: {
    calendar: {
      complete: false,
    },
    archive: {
      complete: false,
    },
  },
};

/**
 * Splits the displayname in firstname and lastname
 * @param {*} displayName
 */
const splitDisplayName = (displayName) => {
  const parts = displayName.split(' ');
  const firstname = parts.shift();
  const lastname = parts.join(' ');
  return [firstname, lastname];
};

/**
 * getUserData returns the data for the currently signed in user
 * if the user is not currently signed in it returns an empty object
 * @return {object} - object containing user data
 * @throws {Error}
 */
const getUserData = async () => {
  checkAuthStatus();
  const { uid } = auth.currentUser;

  try {
    const user = await storage.get('user', uid);
    return { uid, ...user };
  } catch (error) {
    throw new Error('Failed to retrieve user data');
  }
};

/**
 * signInAnonymously creates an anonymous user account and user data document
 * that can be upgraded to a full account when signing up
 */
const signInAnonymously = async () => {
  try {
    const { user } = await auth.signInAnonymously();
    track('login', { method: 'anonymous' });
    await storage.createUser(user, initialUserData);
  } catch (err) {
    throw new Error('Failed to create anonymous account');
  }
};

/**
 * signUpWithEmail upgrades an anonymous user with email credentials
 * @return {object} - user object
 */
const signUpWithEmail = async ({
  firstName,
  lastName,
  marketingConsent,
  email,
  password,
}) => {
  try {
    const { currentUser } = auth;
    const currentUserData = await storage.getUser(currentUser);

    const { user } = await auth.createUserWithEmailAndPassword(email, password);

    // Update the new account with a display name
    await user.updateProfile({ displayName: `${firstName} ${lastName}` });

    // We will update the new user account with the history of their anonymous account
    await storage.updateUser(user, {
      ...currentUserData, firstName, lastName, marketingConsent,
    });

    track('sign_up', { type: 'Email' });

    return user;
  } catch (err) {
    throw new Error('Could not create account');
  }
};

/**
 * signInWithAuthProvider - signs a user in with an auth provider e.g. Google
 * copies the anonymous user doc
 * @param {string} providerLabel - identifier for the configured auth provider
 */
const signInWithAuthProvider = async (providerLabel) => {
  const provider = AuthProviders[providerLabel];
  if (provider === undefined) {
    throw new Error(`No auth provider registered for ${provider}`);
  }

  try {
    const { currentUser } = auth;
    const currentUserData = await storage.getUser(currentUser);

    // Sign in with our selected provider
    const { additionalUserInfo, user } = await auth.signInWithPopup(provider);

    // If it is not a new user, we will just return the logged in user
    if (!additionalUserInfo.isNewUser) return user;

    // This login is not known yet so we are dealing with a new user.
    const { displayName } = user;

    const [firstName, lastName] = splitDisplayName(displayName);

    // We will update the new user account with the history of their anonymous account
    await storage.updateUser(user, { ...currentUserData, firstName, lastName });

    track('sign_up', { type: providerLabel });

    return user;
  } catch (error) {
    throw new Error('Email address was already in use');
  }
};

/**
 * signInWithEmailAndPassword attempts to authenticate the user using email and password
 * @param {string} email - User identifier
 * @param {string} password - User password
 * @return {object} - Raw firebase user object
 * @throws {Error}
 */
const signInWithEmailAndPassword = async (email, password) => {
  let user;
  try {
    user = await auth.signInWithEmailAndPassword(email, password);
    track('login', { method: 'email' });
  } catch (err) {
    throw new Error('Could not sign in');
  }

  return user;
};

/**
 * isAuthenticated returns the current authication status
 */
const isAuthenticated = () => (!!auth.currentUser);

/**
 * getIdToken returns the auth token for the currently
 * signed in user
 */
const getIdToken = () => auth.currentUser.getIdToken();

/**
 * onAuthStateChanged aliases the firebase auth handler
 */
const onAuthStateChanged = auth.onAuthStateChanged.bind(auth);

/**
 * currentUser returns the currently authenticated user
 */
const currentUser = () => auth.currentUser;


/**
 * getSearchHistory returns the users search history
 */
const getSearchHistory = async () => {
  checkAuthStatus();

  const { uid } = auth.currentUser;

  try {
    const searchHistory = await database.ref(`user/${uid}/searchHistory`).once('value');
    return searchHistory.exists() ? searchHistory.val() : [];
  } catch (error) {
    throw new Error('Failed to retrieve users search history');
  }
};

/**
 * pushSearchHistory takes a searchTerm and prepends the user data search history
 * @param {string} searchTerm - search term
 * @return {object} - object containing user data
 * @throws {Error}
 */
const pushSearchHistory = async (search = {}) => {
  checkAuthStatus();

  const { uid } = auth.currentUser;

  const timestamp = new Date().toString();
  const searchHistory = await getSearchHistory();

  const item = {
    ...search,
    timestamp,
  };

  searchHistory.unshift(item);

  await database.ref(`user/${uid}/searchHistory`)
    .set(searchHistory.slice(0, MAX_SEARCH_HISTORY));
};

/**
 * createStream returns a subscriber to listen for changes
 * on the user doc
 * @return {object}
 */
const createStream = () => {
  const { uid } = auth.currentUser;
  if (!uid) {
    return {};
  }
  const ref = database.ref(`user/${uid}`);
  return ref;
};

/**
 * setOnboardingStatus sets the oboarding status for a target onboarding
 * @param {string} onboardingKey - the target onboarding to update
 * @param {boolean} status - the status to set
 */
const setOnboardingCompletion = async (key, value) => {
  const { uid } = auth.currentUser;
  if (!uid) {
    return;
  }

  await database.ref(`user/${uid}/onboarding/${key}/complete`).set(value);
};

/**
 * sendPasswordResetEmail user a link to reset their password
 * @param {string} email - the users email address
 */
const sendPasswordResetEmail = async (email) => {
  await auth.sendPasswordResetEmail(email);
  track('send_password_reset');
};

/**
 * confirmPasswordReset given a code will reset the users password
 */
const confirmPasswordReset = async (code, newPassword) => {
  await auth.confirmPasswordReset(code, newPassword);
  track('confirm_password_reset');
};

/**
 * signOut signs the user out
 */
const signOut = async () => {
  await auth.signOut();
  track('logout');
};

/**
 * destroy - deletes the related docs then deletes the user account
 */
const destroy = async () => {
  const { uid } = auth.currentUser;
  if (!uid) return;
  const ref = database.ref(`user/${uid}`);
  await ref.remove();
  await auth.currentUser.delete();
  track('delete_user');
};

const UserService = {
  signUpWithEmail,
  signInWithEmailAndPassword,
  signInWithAuthProvider,
  signInAnonymously,
  sendPasswordResetEmail,
  confirmPasswordReset,
  signOut,
  getUserData,
  getSearchHistory,
  setOnboardingCompletion,
  isAuthenticated,
  currentUser,
  getIdToken,
  onAuthStateChanged,
  pushSearchHistory,
  createStream,
  destroy,
};

// TODO: remove
// Provide debugging convenience
window.UserService = UserService;

export default UserService;
