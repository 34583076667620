import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ConfirmPassword from '../components/ConfirmPassword';
import InputField from '../components/InputField';
import GoogleLoginButton from '../components/GoogleLoginButton';
import linkedinConversion from '../services/linkedinConversion';

import UserService from '../services/UserService';

const useStyles = makeStyles(() => ({
  errorbox: {
    border: '2px solid red',
    borderRadius: 4,
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const SignUpForm = ({ onSignUp, closeSignIn }) => {
  const style = useStyles();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState(false);

  const [isValidForm, setIsValidForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isValidForm) {
      try {
        const user = await UserService.signUpWithEmail({
          email,
          password,
          firstName,
          lastName,
          marketingConsent: true,
        });

        if (onSignUp) onSignUp(user);
        setAuthError(false);
        linkedinConversion();
        closeSignIn();
      } catch (error) {
        setErrorMessage(error.message);
        setAuthError(true);
      }
    }
  };

  const handlePasswordChange = (change) => {
    setIsValidForm(change.valid);
    setPassword(change.password);
  };

  const changes = {
    firstName: setFirstName,
    lastName: setLastName,
    email: setEmail,
  };

  const handleInputChange = (event) => {
    const { id } = event.target;
    changes[id](event.target.value);
  };

  return (
    <form
      data-testid="signup-form"
      className={style.form}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Grid spacing={2} container alignItems="stretch">
        {authError && (
          <Grid item xs="12">
            <div className={style.errorbox}>
              <p>{errorMessage}</p>
            </div>
          </Grid>
        )}

        <Grid item xs="12" md="6">
          <InputField
            id="firstName"
            placeholder="First Name"
            type="text"
            className={style.loginInput}
            onChange={handleInputChange}
            value={firstName}
          />
        </Grid>

        <Grid item xs="12" md="6">
          <InputField
            id="lastName"
            placeholder="Last Name"
            type="text"
            className={style.loginInput}
            onChange={handleInputChange}
            value={lastName}
          />
        </Grid>

        <Grid item xs="12">
          <InputField
            id="email"
            placeholder="Email"
            type="email"
            className={style.loginInput}
            onChange={handleInputChange}
            value={email}
          />
        </Grid>

        <Grid item xs="12">
          <ConfirmPassword
            onChange={handlePasswordChange}
          />
        </Grid>

        <Grid item xs="12">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            disabled={!isValidForm}
          >
            Continue
          </Button>
        </Grid>

        <Grid item xs="12">
          <Typography align="center" component="p">
            Or
          </Typography>
        </Grid>

        <Grid item xs="12">
          <GoogleLoginButton onSignin={onSignUp} />
        </Grid>

        <Grid item xs="12">
          <Typography align="center" variant="caption" component="p">
            PromoNav part of e.fundamentals Ltd may use the contact information you provide
            to contact you about our products and services.
            You may unsubscribe from these communications at any time.
            Full information can be found in our Privacy Policy.
          </Typography>
        </Grid>

      </Grid>
    </form>
  );
};

SignUpForm.propTypes = {
  onSignUp: PropTypes.func,
  closeSignIn: PropTypes.func,
};

SignUpForm.defaultProps = {
  onSignUp: () => { },
  closeSignIn: () => { },
};

export default SignUpForm;
