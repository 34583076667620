import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { context } from '../store';

// Check a user is authenticated otherwise send to signin screen
const AuthSwitch = ({ children }) => {
  const { state } = useContext(context);

  if (!state.user) {
    return <Redirect to="/app/user/signin" />;
  }

  return children;
};

export default AuthSwitch;
