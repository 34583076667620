import { createMuiTheme } from '@material-ui/core/styles';

const efTheme = createMuiTheme({
  typography: {
    fontFamily: [
      'Monserratt',
      'sans-serif',
    ].join(','),
    h4: {
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#233774',
    },
    secondary: {
      main: '#7a1429',
    },
    efGrey: {
      main: '#ced4da',
    },
  },
  overrides: {
    PrivateNotchedOutline: {
      root: {
        borderWidth: 2,
      },
    },
  },
});

export default {
  efTheme,
};
