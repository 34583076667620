import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import UserService from '../services/UserService';
import InputField from '../components/InputField';
import GoogleLoginButton from '../components/GoogleLoginButton';

const useStyles = makeStyles(() => ({
  errorbox: {
    border: '2px solid red',
    borderRadius: 4,
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const LoginForm = ({
  submitButtonText,
  onSignin,
  errorMessage,
}) => {
  const style = useStyles();


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const user = await UserService.signInWithEmailAndPassword(email, password);
      if (onSignin) onSignin(user);
      setAuthError(false);
    } catch (e) {
      setAuthError(true);
    }
  };

  const changes = {
    email: setEmail,
    password: setPassword,
  };

  const handleInputChange = (event) => {
    const { id } = event.target;
    changes[id](event.target.value);
  };

  return (
    <form data-testid="signin-form" className={style.form} autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {authError && (
        <Grid item xs="12">
          <div className={style.errorbox}>
            <p>{errorMessage}</p>
          </div>
        </Grid>
        )}

        <Grid item xs="12">
          <InputField
            id="email"
            required
            placeholder="Email"
            type="email"
            className={style.loginInput}
            onChange={handleInputChange}
            errorMessage=""
            value={email}
          />
        </Grid>

        <Grid item xs="12">
          <InputField
            id="password"
            required
            placeholder="Password"
            type="password"
            className={style.loginInput}
            onChange={handleInputChange}
            errorMessage=""
            value={password}
          />
        </Grid>

        <Grid item xs="12">
          <Button
            className={style.loginSubmit}
            variant="contained"
            disableElevation
            color="primary"
            type="submit"
            fullWidth
          >
            {submitButtonText}
          </Button>
        </Grid>

        <Grid item xs="12">
          <Button component={Link} to="/app/user/forgotPassword">Forgot password?</Button>
        </Grid>

        <Grid item xs="12">
          <Typography align="center" component="p">
            Or
          </Typography>
        </Grid>

        <Grid item xs="12">
          <GoogleLoginButton onSignin={onSignin} />
        </Grid>
      </Grid>
    </form>
  );
};

LoginForm.propTypes = {
  onSignin: PropTypes.func,
  submitButtonText: PropTypes.string,
  errorMessage: PropTypes.string,
};

LoginForm.defaultProps = {
  submitButtonText: 'Submit',
  errorMessage: 'Error',
  onSignin: () => {},
};

export default LoginForm;
