import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const actions = {
  UPDATE_USER: 'update_user',
  UPDATE_USER_DATA: 'update_user_data',
};

const defaultState = {
  user: null,
  userData: {},
};

const context = React.createContext(defaultState);

const Store = ({ initialState, children }) => {
  const [state, dispatch] = useReducer((currState, action) => {
    switch (action.type) {
      case actions.UPDATE_USER:
        return { ...currState, user: action.user };
      case actions.UPDATE_USER_DATA:
        return {
          ...currState,
          userData: get(action, 'userData', {}),
          searchTerms: get(action, 'userData.lastSearch.journeys', []),
          retailers: get(action, 'userData.lastSearch.retailers', []),
        };
      default:
        throw new Error();
    }
  }, { ...defaultState, ...initialState });

  return (
    <context.Provider value={{ state, dispatch }}>{children}</context.Provider>
  );
};

Store.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  initialState: PropTypes.shape({
    user: PropTypes.oneOf([
      null,
      PropTypes.shape({
        uid: PropTypes.string,
        displayName: PropTypes.string,
      }),
    ]),
    userData: PropTypes.shape({
      searchHistory: PropTypes.array,
      successfulSearchHistory: PropTypes.array,
    }),
  }),
};

Store.defaultProps = {
  children: null,
  initialState: {},
};

export { context, actions };
export default Store;
