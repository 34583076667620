import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const InputField = ({
  id,
  required,
  placeholder,
  type,
  onChange,
  value,
  error,
  errorMessage,
}) => (
  <TextField
    fullWidth
    variant="outlined"
    size="small"
    id={id}
    required={required}
    placeholder={placeholder}
    type={type}
    onChange={onChange}
    value={value}
    error={error}
    helperText={error ? errorMessage : ''}
  />
);

InputField.propTypes = {
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
};

InputField.defaultProps = {
  required: true,
  placeholder: '',
  type: '',
  onChange: () => {},
  value: '',
  error: false,
  errorMessage: '',
  id: '',
};

export default InputField;
