import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});

const LoadingOverlay = () => {
  const styles = useStyles();

  return (
    <div data-testid="loading-overlay" className={styles.overlay}>
      <CircularProgress />
    </div>
  );
};

export default LoadingOverlay;
