import React, { useState, useContext, useEffect } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { get } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';

import UserService from '../services/UserService';
import { context } from '../store';
import track from '../services/tracking';

const config = [
  {
    disableBeacon: true,
    target: '[data-component="SearchBar"]',
    title: <b style={{ fontSize: '16px' }}>Search Bar</b>,
    content:
  <div style={{ fontSize: '15px' }}>
    Type in queries here. Try searching for:
    <ul style={{ textAlign: 'left', margin: '5px auto 0' }}>
      <li>Your brand or a competitor brand</li>
      <li>Refine results with a pack size or variant name</li>
    </ul>
  </div>,
    spotlightClicks: true,
    hideBackButton: true,
  },
  {
    disableBeacon: true,
    target: '[data-testid="RetailerSelect"]',
    title: <b style={{ fontSize: '16px' }}>Retailer Options</b>,
    content:
  <div style={{ fontSize: '15px' }}>
    Choose which retailers you would like to search for promotions
  </div>,
    spotlightClicks: true,
    hideBackButton: true,
  },
  {
    disableBeacon: true,
    target: '[data-component="PromoBar"]',
    title: <b style={{ fontSize: '16px' }}>Promotions</b>,
    content:
  <div style={{ fontSize: '15px' }}>
    Try clicking a promotion to view a screenshot of the product page
  </div>,
    spotlightClicks: true,
    hideBackButton: true,
  },
  {
    disableBeacon: true,
    target: '[data-component="WordSelector"][tabindex="1"]',
    title: <b style={{ fontSize: '16px' }}>Keywords</b>,
    content:
  <div style={{ fontSize: '15px' }}>
    Clicking a keyword will add it to your search terms
  </div>,
    spotlightClicks: true,
    hideBackButton: true,
  },
];

const mobileSteps = [
  {
    disableBeacon: true,
    target: '[data-testid="RetailerSelect"]',
    title: <b style={{ fontSize: '16px' }}>Detailed View</b>,
    content:
  <div style={{ fontSize: '15px' }}>
    <div><ScreenRotationIcon /></div>
    Rotate your phone to see the calendar of promotions in greater detail
  </div>,
    spotlightClicks: true,
    hideBackButton: true,
    placement: 'center',
  },
];

const CalendarOnboarding = () => {
  const { state } = useContext(context);
  const largeScreenSize = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const init = !get(state, 'userData.onboarding.calendar.complete', false);

  const [steps, setSteps] = useState(largeScreenSize ? config : [...config, ...mobileSteps]);
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);

  // hacky waiting on the userData fetch before setting
  // the running state
  useEffect(() => {
    const t = setTimeout(() => {
      setRun(init);
    }, 4000);

    setSteps(largeScreenSize ? config : [...config, ...mobileSteps]);

    return () => {
      clearTimeout(t);
    };
  }, [init, largeScreenSize]);

  const handleJoyrideCallback = (data) => {
    const {
      action, index, type, status,
    } = data;
    track('onboarding_step', { step: index });

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Just fire and forget for now
      track('onboarding_end', { step: index, reason: status === STATUS.SKIPPED ? 'skipped' : 'finished' });
      UserService.setOnboardingCompletion('calendar', true);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const newStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 0) {
        setTimeout(() => {
          setRun(true);
        }, 1000);
      }

      // Update state to advance the tour
      setStepIndex(newStepIndex);
    }
  };

  return (
    <Joyride
      steps={steps}
      continuous
      run={run}
      stepIndex={stepIndex}
      showSkipButton
      callback={handleJoyrideCallback}
      locale={{
        back: 'Back',
        close: 'Close',
        last: 'Finish tour',
        next: 'Next',
        skip: 'Skip',
      }}
    />
  );
};

export default CalendarOnboarding;
