import React, {
  useEffect, useContext, useState, useRef,
} from 'react';
import { FullStoryAPI } from 'react-fullstory';
import PropTypes from 'prop-types';
import UserService from '../services/UserService';
import LoadingOverlay from './LoadingOverlay';
import { context, actions } from '../store';

const identifyFullStory = (uid, fullname) => {
  FullStoryAPI('identify', uid, {
    displayName: fullname,
  });
};

const AuthState = ({ children }) => {
  const { dispatch } = useContext(context);
  const [authRan, setAuthRan] = useState(false);

  const ref = useRef(null);

  const userDataListener = (snapshot) => {
    const v = snapshot.val();
    dispatch({ type: actions.UPDATE_USER_DATA, userData: v });
  };

  const bindAuthStateHandler = () => {
    UserService.onAuthStateChanged(async (user) => {
      if (user === null) {
        await UserService.signInAnonymously();
      } else {
        if (ref.current) {
          ref.current.off('value', userDataListener);
        }

        ref.current = UserService.createStream();
        ref.current.on('value', userDataListener);
        identifyFullStory(user.uid, user.displayName);
        dispatch({ type: actions.UPDATE_USER, user });

        setAuthRan(true);
      }
    });
  };

  useEffect(() => {
    bindAuthStateHandler();
  }, []);

  if (!authRan) {
    return <LoadingOverlay />;
  }

  return <>{children}</>;
};

AuthState.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

AuthState.defaultProps = {
  children: null,
};

export default AuthState;
