import React from 'react';
import PropTypes from 'prop-types';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  box: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    minHeight: 400,
  },
}));

const NoData = ({ children }) => {
  const classes = useStyles();

  return (
    <Container disableGutters className={classes.box}>
      <div>
        <Typography component="p" variant="h6">
          {children}
        </Typography>
      </div>
    </Container>
  );
};

NoData.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

NoData.defaultProps = {
  children: 'It looks like you’re searching for something we haven’t yet added to our database',
};

export default NoData;
