import React from 'react';
import Slider from '@material-ui/core/Slider';
import PropTypes from 'prop-types';

class WeekTimeline extends React.Component {
 handleChange = (event, value) => {
   const { update } = this.props;
   update(value);
 }

 valuetext = (value) => `${value}`;

 render() {
   const { promotionDates } = this.props;
   return (
     <Slider
       defaultValue={promotionDates.length - 1}
       min={0}
       max={promotionDates.length - 1}
       getAriaValueText={this.valuetext}
       step={null}
       marks={promotionDates}
       onChangeCommitted={this.handleChange}
     />
   );
 }
}

WeekTimeline.propTypes = {
  promotionDates: PropTypes.arrayOf(PropTypes.string),
  update: PropTypes.func,
};

WeekTimeline.defaultProps = {
  promotionDates: [],
  update: () => {},
};

export default WeekTimeline;
