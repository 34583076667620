/* eslint-disable consistent-return */
import React, { useState, useContext, useEffect } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import UserService from '../services/UserService';
import { context } from '../store';
import track from '../services/tracking';

const config = [
  {
    disableBeacon: true,
    hideBackButton: true,
    target: 'span.MuiSlider-thumb',
    title: <b style={{ fontSize: '16px' }}>Archive Page</b>,
    content: (
      <div style={{ fontSize: '15px' }}>
        Try dragging the slider to view the product page on different days
      </div>
    ),
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    hideFooter: true,
  },
];

const ArchiveOnboarding = ({ archiveOpen }) => {
  const { state } = useContext(context);

  const init = !get(state, 'userData.onboarding.archive.complete', false);

  const [steps] = useState(config);
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);

  // hacky waiting on the userData fetch before setting
  // the running state
  useEffect(() => {
    if (archiveOpen) {
      const t = setTimeout(() => {
        setRun(init);
      }, 750);
      return () => {
        clearTimeout(t);
      };
    }
  }, [init, archiveOpen]);

  const handleJoyrideCallback = (data) => {
    const {
      action, index, type, status,
    } = data;
    track('onboarding_step', { step: index });
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Just fire and forget for now
      track('onboarding_end', {
        step: index,
        reason: status === STATUS.SKIPPED ? 'skipped' : 'finished',
      });
      UserService.setOnboardingCompletion('archive', true);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const newStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 0) {
        setTimeout(() => {
          setRun(true);
        }, 400);
        setStepIndex(newStepIndex);
      } else {
        // Update state to advance the tour
        setStepIndex(newStepIndex);
      }
    }
  };

  return (
    <Joyride
      steps={steps}
      continuous
      run={run}
      stepIndex={stepIndex}
      showSkipButton
      callback={handleJoyrideCallback}
      locale={{
        back: 'Back',
        close: 'Close',
        last: 'Finish tour',
        next: 'Next',
        skip: 'Skip',
      }}
    />
  );
};

ArchiveOnboarding.propTypes = {
  archiveOpen: PropTypes.bool.isRequired,
};

export default ArchiveOnboarding;
