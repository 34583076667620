import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { MenuItem } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  placeholder: {
    color: theme.palette.grey[400],
  },
}));

export const StyledInput = withStyles((theme) => ({
  input: {
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '0.1rem solid #ced4da',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus, &:hover, &:active': {
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      border: '0.1rem solid #3f51b5',
    },
  },
}))(InputBase);

const DateSelectDropdown = ({ promotionDateOptions, update }) => {
  const classes = useStyles();
  const [promotionDate, setPromotionDate] = useState('');
  const [promotionDateOpts, setPromotionDateOpts] = useState([]);

  useEffect(() => {
    setPromotionDate(promotionDate);
    setPromotionDateOpts(promotionDateOptions);
  }, [promotionDateOptions, promotionDate]);

  const handleChange = (event) => {
    const selected = event.target.value;
    update(selected);
    setPromotionDate(selected);
  };

  const generateOptions = () => promotionDateOpts.map(({ label, value }) => (
    <MenuItem role="option" key={value} value={value}>
      {label}
    </MenuItem>
  ));

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        className={classes.select}
        value={promotionDate}
        onChange={handleChange}
        variant="outlined"
        label="Promotion Date Select"
        displayEmpty
        input={<StyledInput defaultValue="Retailers" />}
        inputProps={{
          name: 'date',
          id: 'date-select',
        }}
      >
        <MenuItem value="" className={classes.placeholder}>Select Date</MenuItem>
        {generateOptions()}
      </Select>
    </FormControl>
  );
};

DateSelectDropdown.propTypes = {
  promotionDateOptions: PropTypes.arrayOf(PropTypes.object),
  update: PropTypes.func,
};

DateSelectDropdown.defaultProps = {
  promotionDateOptions: [],
  update: () => { },
};

export default DateSelectDropdown;
