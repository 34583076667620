import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const space = 24;

const useStyles = makeStyles(({ palette }) => ({
  header: {
    padding: `4px ${space}px 0`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  avatar: {
    width: '2.5em',
    height: '2.5em',
    transform: 'translateX(60%) translateY(-20%)',
    '& > img': {
      margin: 0,
      backgroundColor: palette.common.white,
    },
  },
}));


const RetailerCardHeader = ({ retailer }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Avatar alt={`${retailer} logo`} variant="rounded" className={classes.avatar} src={`https://static.ef.uk.com/latest/images/retailer/${retailer.toLowerCase()}.svg`} />
      </div>
    </div>
  );
};

RetailerCardHeader.propTypes = {
  retailer: PropTypes.string.isRequired,
};

export default RetailerCardHeader;
