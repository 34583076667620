import React, { useEffect, useContext } from 'react';
import { isEmpty, get } from 'lodash';
import useUrlFilters from '../hooks/useUrlFilters';
import UserService, { defaultRetailers } from '../services/UserService';
import { context } from '../store';

const empty = [];

const UrlFilter = () => {
  const { urlFilters, setUrlFilters } = useUrlFilters();
  const { state } = useContext(context);

  const currentRetailers = get(state, 'userData.searchHistory[0].retailers', empty);
  const currentSearchTerms = get(state, 'userData.searchHistory[0].searchTerms', empty);

  // when url filters are set update the searchHistory on mount
  useEffect(() => {
    const hasRetailers = !isEmpty(urlFilters.retailers);
    const hasSearchTerms = !isEmpty(urlFilters.searchTerms);

    if (hasSearchTerms) {
      UserService.pushSearchHistory({
        retailers: hasRetailers ? urlFilters.retailers : defaultRetailers,
        searchTerms: urlFilters.searchTerms,
      });
    }
  }, []);

  // update the url when search terms or retailers change
  useEffect(() => {
    setUrlFilters({
      retailers: currentRetailers,
      searchTerms: currentSearchTerms,
    });
  }, [currentRetailers, currentSearchTerms]);

  return <></>;
};

export default UrlFilter;
