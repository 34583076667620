/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContent, DialogContentText, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    height: '80%',
  },
  close_button: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
}));

// eslint-disable-next-line react/prop-types
const CookiePopUp = ({ onClose, isOpen }) => {
  const classes = useStyles();
  return (
    <Dialog id="cookiesInfo" open={isOpen} classes={{ root: classes.root }}>
      <DialogTitle>
        <Typography variant="h3">Privacy Policy</Typography>
        <IconButton className={classes.close_button} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <DialogContentText>Publication date: April 14, 2020</DialogContentText>
          <Typography variant="h4">Introduction</Typography>
          <DialogContentText>
            E.fundamentals (“
            <strong>Promonav</strong>
            " and also referred to as “
            <strong>our</strong>
            ”, “
            <strong>us</strong>
            ” and “
            <strong>we</strong>
            ”) understands the importance of user privacy and takes privacy matters very seriously.
            <br />
            By visiting Promonav’s existing and future website(s), application(s) and/or domain name(s) at https://promonav.io, including its sub-domains and mobile optimized version (collectively, the “
            <strong>Site</strong>
            ”), or otherwise accessing any linked pages,
            features, content, products, services, mobile properties and mobile device applications
            for iPhone, iPad, Android or other smartphone or tablet device (each, a "
            <strong>Service</strong>
            ") owned or operated by E.fundamentals, you acknowledge and accept the terms in this
            Privacy Policy (“
            <strong>Policy</strong>
            ”).
            <br />
            This Policy is a legally binding agreement between Miro and, you, a user of our Services
            and/or visitor of our Site; and your acceptance of this Policy arises upon your simple
            use of the Services, your use of the Site, or when you otherwise click on the “I accept”
            checkbox on our registration page (or similar box or means). If you do not agree to the
            terms of this Policy, please close this web tab and immediately stop browsing our Site
            and/or using the Services.
            <br />
            A separate agreement and/or terms and conditions governs delivery, access and use of
            the Services (the “
            <strong>Customer Agreement</strong>
            ”), including the processing of any messages, files or other content submitted through
            Services accounts. This Policy shall be incorporated by reference into the Customer
            Agreement. The organization (e.g., your employer or other entity or person) that
            entered into the Customer Agreement (“
            <strong>Customer</strong>
            ”) controls the terms governing the Services and any associated Customer Data.
            If you have any questions about specific settings and privacy practices, please contact
            the administrator for your Promonav account.
            <br />
            This Privacy Policy does not apply to any third party applications or software that
            integrate with the Services (“
            <strong>Third Party Services</strong>
            ”), or any other third party products, services or businesses.
          </DialogContentText>
          <Typography variant="h4">Information We Collect</Typography>
          <DialogContentText>
            We collect a variety of personally identifiable information ("PII") about users of our
            Services in order to ensure a quality user experience. "PII" means information that we
            can use to identify or contact you, such as your name, address, telephone number, email
            address or other contact information. You are responsible for ensuring the accuracy of
            all PII that you submit to us. Inaccurate information may affect your experience when
            using the Services and/or our ability to contact you as described in this Policy.
            PII collected by us is protected as personal data under applicable data protection law.
            PII does not include "Aggregated Information" which is information or data we collect
            where individual user identities have been removed, including metadata on your use of
            the Services, IP address logs, device and location information. Aggregated Information
            helps us understand trends, user needs and other information to provide improved
            Services and may be used by us for any purpose.
            We may also collect other non-personally identifiable information about your use and
            interaction with our Services. "Non-personally identifiable information" means
            information where we cannot determine the identity of a natural person. Non-personally
            identifiable information is not treated as PII unless we combine it with or link it to
            PII that you give to us.
            If you do not wish to disclose any required information, then your sole remedies shall
            be to close this web tab, delete your Promonav account and/or exit the Site
          </DialogContentText>
          <Typography variant="h4">How We Collect Information</Typography>
          <DialogContentText>
            <strong>Information You Give Us</strong>
            <br />
            We collect information that you give to us when you register or use our Services.
            When you register for a Promonav user account ("
            <strong>Account</strong>
            ") on the website or mobile application, you will be assigned a unique account
            identifier which will be associated with any information you give to us under your
            Account. You may also give us information to allow us to contact you or use certain
            features available through our Services without signing up for an Account, such as
            when you fill out a request form, provide feedback, email us, or engage in
            communications with our team. While you may use some functionality of the Services
            and/or Site without registering for an Account, most functionality and services will
            require that you register for an Account.
          </DialogContentText>
          <br />
          <DialogContentText>
            <strong>Customer Support Information</strong>
            <br />
            Your emails, calls and other correspondence to and from us may be recorded for various
            purposes including: monitoring customer service quality or compliance, checking accuracy
            of the information you provide us, preventing fraud or providing training for our staff
            or customer service representatives. Any information obtained from you through Customer
            support will be treated in accordance with the provisions of this Policy.
          </DialogContentText>
          <br />
          <DialogContentText>
            <strong>Information We Collect Automatically</strong>
            <br />
            Even if you do not provide information to us, we automatically collect certain
            information about your use and interaction with our Site and/or Services. For
            example, when you visit our website, our systems automatically maintain web logs
            to record data about all visitors who use our website and stores this information
            in our database. These web logs may contain information about you including the
            following: IP address, type(s) of operating system you use, type of device you use,
            date and time you visited the website, your activity and/or referring websites.
            We use your log information to troubleshoot problems, gather demographic information,
            customize your experience when accessing our Site, Services and other business purposes.
          </DialogContentText>
          <br />
          <DialogContentText>
            <strong>Information Collected From Other Sources</strong>
            <br />
            We may receive information about you from outside sources, such as commercially
            available demographic or marketing information, and add or combine it with your
            information to provide better service to you and inform you of Services or other
            information that may be of interest to you.
            Our Site offers you the functionality of using your social media credentials
            (e.g. Google etc.) to use single-sign-on to enter our Site, and in that manner, we may
            also collect certain information from you as you log on. We will not collect more
            information from you when using your social media credentials beyond the information
            such third parties disclose to us.
          </DialogContentText>
          <Typography variant="h4">How We Use Information</Typography>
          <DialogContentText>
            In order for us to provide and maintain our Site and Services, and to improve your user
            experience, we will use your information in accordance with your instructions, including
            any applicable terms in your Customer Agreement and applicable law. Promonav uses your
            information in furtherance of our legitimate interests in operating our Site, Services
            and business. Additionally, Promonav uses your information to
          </DialogContentText>
          <ul>
            <Typography component="li" color="textSecondary">
              Develop, research, process, safeguard, modify and improve our communications,
              the Site and Services.
            </Typography>
            <Typography component="li" color="textSecondary">
              Send correspondence to your e-mail address, including sending password verification
              and retrieval links, billing, account management information, newsletters, answers
              to queries/suggestions and other notices related to the Site and Services.
            </Typography>
            <Typography component="li" color="textSecondary">
              Improve your user experience and provide you with customer support
            </Typography>
            <Typography component="li" color="textSecondary">
              Investigate and help prevent security issues, abuse and breaches.
            </Typography>
            <Typography component="li" color="textSecondary">
              As required by applicable law, legal process or regulation.
            </Typography>
          </ul>
          <Typography variant="h4">With Whom We Share Information</Typography>
          <DialogContentText>
            <strong>Disclosure With Your Consent</strong>
            <br />
            Customers determine their own policies and practices for the sharing and disclosure
            of information, and Promonav does not control how a Customer or any other third parties
            choose to share or disclose information. We may disclose your information with your
            consent, which we may obtain in a number of ways, including:
          </DialogContentText>
          <ul>
            <Typography component="li" color="textSecondary">In writting</Typography>
            <Typography component="li" color="textSecondary">Verbally</Typography>
            <Typography component="li" color="textSecondary">Online, by clicking on a link button; or</Typography>
            <Typography component="li" color="textSecondary">Other mechanism</Typography>
          </ul>
          <DialogContentText>
            Our Services permit you to submit information which may be displayed to other authorized
            users in the same or different Customer Account. The sharing and other controls applied
            to such information may be determined by you, other users and/or an administrator of
            your Account.
          </DialogContentText>
          <DialogContentText>
            We may share your information with third parties as necessary to provide Services to
            you or to support the technical operation and/or maintenance of our Services, including
            third party application that you may engage within the Services. We may also share
            information with our affiliates, agents, outside vendors or service providers to
            perform functions on our behalf. When a third party acts solely on our behalf and
            otherwise as applicable, we use reasonable commercial efforts to require that party to
            follow the privacy practices stated in this Policy or have complementary privacy
            protections to protect your information. We do not share your information with
            unaffiliated third parties except as permitted to do so in this Policy.
          </DialogContentText>
          <DialogContentText>
            <strong>Disclosure Without Your Consent</strong>
            <br />
            In general, we may disclose your information without your consent to disclosure
            when we reasonably believe disclosure is appropriate in order to:
          </DialogContentText>
          <ul>
            <Typography component="li" color="textSecondary">Comply with the law (e.g., lawful subpoena or court order);</Typography>
            <Typography component="li" color="textSecondary">
              Cooperate with or report to law enforcement agencies in investigations that
              involve users who use our Service Offerings for activities that are or seem illegal
              or illegitimate activities;
            </Typography>
            <Typography component="li" color="textSecondary">Enforce or apply agreements for our Service Offerings; or</Typography>
            <Typography component="li" color="textSecondary">
              Protect our rights or property or that of our affiliates, including respective
              officers, directors, employees, agents, third party content providers, suppliers,
              sponsors, or licensors (e.g., to address allegations about fraudulent or unlawful
              activity related to a Promonav account).
            </Typography>
            <Typography component="li" color="textSecondary">
              In connection with a merger, acquisition, public offering, sale of company assets,
              insolvency, bankruptcy, or receivership, subject to standard confidentiality
              requirements.
            </Typography>
            <Typography component="li" color="textSecondary">
              To defend Promonav and our affiliates, licensors, officers, agents and representatives
              from legal claims and processes brought to us by third parties (including takedown
              notices);
            </Typography>
            <Typography component="li" color="textSecondary">Use or disclose aggregated or de-identified data in our sole discretion.</Typography>
          </ul>
          <Typography variant="h4">How We Protect Information</Typography>
          <DialogContentText>
            We use industry standard protocols and technology to protect your registered user
            information and personal data in order to guard and encrypt data for transmission
            in a format that prevents data theft by unauthorized third parties, including internal
            reviews of our data collection, storage and processing practices, security measures,
            and physical security measures. However, please take into account that the Internet
            and email transmissions are not secure or error free communication means.
            We also urge you to take additional steps on your own to safeguard and maintain the
            integrity of your information. For example, you should never share your Account or
            login information with other people and be sure to sign off when finished using a
            shared or public computer. We urge you to be aware that if you use or access our
            Services through a third party computer network (e.g., internet café, library) or
            other potentially non-secure internet connection, such use is not recommended and is
            solely at your own risk. It is your responsibility to check beforehand on the privacy
            and/or security policy of your network prior to accessing the Services. We are not
            responsible for your handling, sharing, re-sharing and/or distribution of your
            information except as set forth in the Policy.
          </DialogContentText>
          <Typography variant="h4">What Choices Do You Have</Typography>
          <DialogContentText>
            <strong>Control of your information</strong>
            <br />
            Customers will generally be able to edit, select or delete the amount and type of
            personal data they disclose to us when using our Site and services. This will be managed
            through your Account with us, the settings section of your account, the Site, and
            through the means mentioned in this Policy.
          </DialogContentText>
          <DialogContentText>
            <strong>Personal data owners' rights and revocation of consent</strong>
            <br />
            Any user of our Site shall have the right to make a request to us in order to enforce
            such user’s rights to:
          </DialogContentText>
          <ul>
            <Typography component="li" color="textSecondary">Access the user’s personal data;</Typography>
            <Typography component="li" color="textSecondary">Rectify the user’s personal data, when inaccurate or incomplete;</Typography>
            <Typography component="li" color="textSecondary">Cancel the process of the user’s personal data;</Typography>
            <Typography component="li" color="textSecondary">
              Object —with legitimate reasons— the process of the user’s personal data; and/or
            </Typography>
            <Typography component="li" color="textSecondary">
              Revoke the user’s consent for the use, storage, treatment, process and/or, disclosure
              of the user’s personal data.
            </Typography>
          </ul>
          <DialogContentText>
            The enforcement and upholding of your privacy rights should be requested to us in
            writing and must include, at a minimum, the following information: (i) your complete
            name, address and/or email address in order for us to notify you the response to your
            privacy request; (ii) attached documents establishing your identity; and (iii) a clear
            and concise description of the personal data with regard to which you seek to enforce
            any of your privacy rights. If you request rectification, please indicate amendments
            to be made and attach documentation to back up your request.
          </DialogContentText>
          <DialogContentText>
            Upon receipt of your privacy request, and after due review of its merit, we may then
            edit, deactivate and/or delete your personal data from our Site or services. We may not
            be able to delete all of your data from some of our databases and that, if such is the
            case, we will then mark such data as permanently inaccessible.
          </DialogContentText>
          <Typography variant="h4">Cookies and Beacons</Typography>
          <DialogContentText>
            We may place ‘cookies’ and similar technologies in your computer or mobile device in
            order to track and collect data regarding your use of our Site and Services. Cookies
            are small text files that our Site transfers to you and that allow us to permit us to
            recognize you and obtain data such as how, when and how long you browse pages in our
            Site. Either we or our affiliates and authorized service providers may also use
            ‘beacons’, which are small files, sometimes only a pixel in size, embedded onto the
            pages of our Site. Beacons are used to identify each of our pages in order to be
            analyzed by our system tools. You can always configure your browser to refuse cookies
            and beacons. For more information, please visit www.allaboutcookies.com.
          </DialogContentText>
          <Typography variant="h4">Third Party Services</Typography>
          <DialogContentText>
            <strong>Generally</strong>
            <br />
            Our Site and services may also implement hyperlinks to the websites of our commercial
            partners and other third parties. If you click on such links, you are choosing to visit
            such websites, and will be redirected thereto. If you click on a third party link, our
            Policy and Service terms are no longer applicable and your browsing is at your own risk.
            We are not responsible for the privacy and personal data practices used by such third
            parties (including any tools, cookies, information or content contained thereinto), and
            we do not have control over the manner in which such third parties may collect, process,
            treat or use your personal data. You are strongly advised to check the privacy settings,
            policies and/or notices applicable to such third party sites and services prior to
            browsing or using such third party websites and/or services.
          </DialogContentText>
          <DialogContentText>
            In addition, any banner or ad that we may have on our Site does not constitute any
            endorsement of any third party thereof.
          </DialogContentText>
          <DialogContentText>
            <strong>Financial data and information</strong>
            <br />
            We use third party payment processing platforms. These platforms will request you to
            disclose them sensitive personal data (e.g. credit card number, account username and
            password) in order to complete purchases and operations related to our services. Such
            third party payment processing platforms are governed by their own safety standards,
            policies and terms of use. We shall not be liable for their operation, safety or
            otherwise their functioning. We strongly advise our users to engage in online safety
            measures in order to protect their financial information, here in the Site and
            elsewhere. We will not collect or store any sensitive financial data of yours.
          </DialogContentText>
          <Typography variant="h4">Advertising, opt-out</Typography>
          <DialogContentText>
            From time to time, we may use Google Analytics, AdWords and/or AdSense tracking codes,
            along with other third party software tools (such as remarketing codes) in order to
            collect marketing analytics about the pages you browse on our Site or when you undertake
            specific actions through our services. These platforms may also use cookies, codes and
            other technologies to help us analyze our users’ patterns.
            <br />
            For example, third party vendors may show you Promonav’s ads on certain websites across
            the Internet, even after you leave our Site. The collected information is anonymized,
            meaning it cannot be tracked back to individuals. Using Google and other analytics
            tools, we learn how to optimize, and serve ads based on a user’s past visits, giving you
            a better experience.
            <br />
            Our third party vendors may also use cookies and beacons to track your Internet browsing
            in order to serve you ads based on what they or us believe to be of your interest. Such
            information will be processed for purposes of evaluating your browsing activities and
            consumer preferences. Henceforth, by using this Site, you consent to such disclosure,
            treatment and storage of data and/or personal data about you, either by us or by such
            third parties.
            <br />
            In order to enforce and uphold your right to privacy, you have the option to elect not
            to receive this type of advertising from us or third parties, now or in the future. You
            can learn more about how to opt-out by browsing Google’s opting-out and privacy pages
            located at www.google.com, or the Network Advertising Initiative website located at
            www.networkadvertising.org. In addition, you may set browser and system preferences for
            how other third parties serve ads to you.
          </DialogContentText>
          <Typography variant="h4">Regional and International Provisions</Typography>
          <DialogContentText>
            <strong>International Data Transfers and Contractual Terms</strong>
            <br />
            E.fundamentals has its headquarters in the United Kingdom (‘UK’). Henceforth, your
            personal data may be accessed by us or our affiliates, agents, partners, or third
            party service providers elsewhere, and you hereby consent to such access and transfer
            by providing us with such information. If you are accessing our Site from other
            regions, you ought to know that you are thereby transferring your personal data to the
            UK and, thus, you hereby consent to such transfer to the UK or to any other country in
            which we operate.
            <br />
          </DialogContentText>
          <DialogContentText>
            <strong>European Union Model Clauses</strong>
            <br />
            Promonav offers a Data Protection Agreement based on the European Union Model Clauses,
            also known as Standard Contractual Clauses, to meet the adequacy and security
            requirements for our Customers that operate in the European Union, and other
            international transfers of Customer Data.
            <br />
          </DialogContentText>
          <DialogContentText>
            <strong>Your Rights</strong>
            <br />
            Individuals located in certain countries, including the European Economic Area,
            have certain statutory rights in relation to their personal data. Subject to any
            exemptions provided by law, you may have the right to request access to your
            information, as well as to seek to update, delete or correct this information.
            <br />
            To the extent that Promonav’s processing of your personal data is subject to the
            General Data Protection Regulation (GDPR), Promonav relies on its legitimate interests,
            described above, to process your data. Promonav may also process information that
            constitutes your personal data for direct marketing purposes, provided that you have a
            right to object to Promonav’s use of your personal data for this purpose at any time.
          </DialogContentText>
          <Typography variant="h4">Age Restriction</Typography>
          <DialogContentText>
            Promonav does not knowingly collect any kind of information from persons under the age
            of thirteen (13). By using our Services and/or browsing the Site, you hereby represent
            and warrant that (i) you are at least thirteen (13) years of age — or older — as of the
            date of first access to the Site; and, (ii) if you are a minor (which may differ
            depending on the jurisdiction where you reside), you are accessing the Site under the
            direct supervision of your parent or legal guardian.
          </DialogContentText>
          <Typography variant="h4">Amendments</Typography>
          <DialogContentText>
            Promonav may change this Policy from time to time as laws, regulations, industry
            standards and/or our business evolves. We will post the changes to this page and
            encourage you to review our Policy periodically to stay informed. If we make changes
            that materially alter your privacy rights, Promonav will provide additional notice,
            such as via email or through the Services. If you disagree with the changes to this
            Policy, you should deactivate your Services Account. Contact your Account administrator
            if you wish to request the removal of your personal data under their control.
          </DialogContentText>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CookiePopUp;
