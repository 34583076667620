import React from 'react';
import { Typography } from '@material-ui/core';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';
import Layout from './Layout';
import FormWrapper from './FormWrapper';

const ForgotPassword = () => (
  <FormWrapper>
    <Typography variant="h4" component="h2" align="center">
      Reset Password
    </Typography>
    <ForgotPasswordForm />
  </FormWrapper>
);

export default Layout({ Content: ForgotPassword });
