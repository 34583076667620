import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AuthenticationModal from '../modals/AuthenticationModal';
import { context } from '../store';

const GaurdModal = ({ children, check }) => {
  const { state } = useContext(context);

  const canAccess = check(state) || false;

  return (
    <>
      <AuthenticationModal open={!canAccess} />
      {children}
    </>
  );
};

GaurdModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  check: PropTypes.func,
};

GaurdModal.defaultProps = {
  children: null,
  check: () => {},
};

export default GaurdModal;
