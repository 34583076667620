const configs = {
  dev01: {
    apiKey: 'AIzaSyDvwXUFsuB4-lKOIVczlmv8ZuUxB6-1ZiE',
    authDomain: 'efundamentals-dev01.firebaseapp.com',
    databaseURL: 'https://efundamentals-dev01.firebaseio.com',
    projectId: 'efundamentals-dev01',
    storageBucket: 'efundamentals-dev01.appspot.com',
    messagingSenderId: '495088295082',
    appId: '1:495088295082:web:814779076f9a60015a84d3',
    measurementId: 'G-2RBTDFREKH',
  },
  dev02: {
    apiKey: 'AIzaSyCpQVNXnwmEKdnQdQCwb22a_8Et3udCuss',
    authDomain: 'efundamentals-dev02.firebaseapp.com',
    databaseURL: 'https://efundamentals-dev02.firebaseio.com',
    projectId: 'efundamentals-dev02',
    storageBucket: 'efundamentals-dev02.appspot.com',
    messagingSenderId: '43371813853',
    appId: '1:43371813853:web:31ebf162760cfed2593434',
    measurementId: 'G-M5RVMF98CX',
  },
  dev03: {
    apiKey: 'AIzaSyAUgOyQrf3apMYGV9VwPXwPcsjbwox_o2w',
    authDomain: 'efundamentals-dev03-74a9d.firebaseapp.com',
    databaseURL: 'https://efundamentals-dev03-74a9d.firebaseio.com',
    projectId: 'efundamentals-dev03-74a9d',
    storageBucket: 'efundamentals-dev03-74a9d.appspot.com',
    messagingSenderId: '128918300880',
    appId: '1:128918300880:web:60265bee6795defd8256d5',
    measurementId: 'G-58LFBJY7PQ',
  },
  test: {
    apiKey: 'AIzaSyDrzjXzlr0dj5rG9jPCLc03Iia7QMJGdZE',
    authDomain: 'efundamentals-test.firebaseapp.com',
    databaseURL: 'https://efundamentals-test.firebaseio.com',
    projectId: 'efundamentals-test',
    storageBucket: 'efundamentals-test.appspot.com',
    messagingSenderId: '564535606624',
    appId: '1:564535606624:web:4159b8c9988d2b91abf205',
    measurementId: 'G-0TQ4GSJM5L',
  },
  prod: {
    apiKey: 'AIzaSyC6oJ02JKTfNA3MMtDgb7XEmycNntlRxIk',
    authDomain: 'efundamentals-prod.firebaseapp.com',
    databaseURL: 'https://efundamentals-prod.firebaseio.com',
    projectId: 'efundamentals-prod',
    storageBucket: 'efundamentals-prod.appspot.com',
    messagingSenderId: '726183744667',
    appId: '1:726183744667:web:5aeb10f50553004dbc223e',
    measurementId: 'G-J5XS4QBF5C',
  },
};

const getConfig = (hostname) => {
  if (hostname.startsWith('efundamentals-dev01')) return configs.dev01;
  if (hostname.startsWith('efundamentals-dev03')) return configs.dev03;
  if (hostname.startsWith('efundamentals-test')) return configs.test;
  if (hostname.startsWith('efundamentals-prod') || hostname.includes('promonav.io')) return configs.prod;
  return configs.dev02;
};

const url = new URL(window.location.href);
const config = getConfig(url.hostname);
export default config;
