/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: { margin: 'auto', width: '85%' },
  formControl: {
    width: '100%',
  },
  placeholder: {
    color: theme.palette.grey[400],
  },
}));

export const StyledInput = withStyles((theme) => ({
  input: {
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '0.1rem solid #ced4da',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus, &:hover, &:active': {
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      border: '0.1rem solid #3f51b5',
    },
  },
}))(InputBase);

const RetailerSelect = ({ retailerOptions, selectedRetailers, onChange }) => {
  const [retailers, setRetailers] = useState([]);

  const largeScreenSize = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    if (selectedRetailers.length === 0) {
      setRetailers(retailerOptions);
    } else {
      setRetailers(selectedRetailers);
    }
  }, [selectedRetailers, retailerOptions]);

  const handleChange = (event) => {
    const selected = event.target.value;
    onChange(selected);
    setRetailers(selected);
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            multiple
            data-testid="RetailerSelect"
            value={retailers}
            variant="outlined"
            onChange={handleChange}
            label="Retailer Select"
            displayEmpty
            renderValue={(selected) => {
              const length = selected.length;
              if (!largeScreenSize && length > 1) {
                return `${length} retailers selected`;
              }
              if (largeScreenSize && length > 3) {
                const remainder = length - 3;
                return `${selected.slice(0, 3).join(', ')} and ${remainder} more`;
              }
              return selected.join(', ');
            }}
            input={<StyledInput defaultValue="Retailers" />}
            inputProps={{
              name: 'retailer',
              id: 'retailer-select',
              'data-testid': 'RetailerSelectInput',
            }}
          >
            {retailerOptions.map((value) => (
              <MenuItem role="option" key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

RetailerSelect.propTypes = {
  selectedRetailers: PropTypes.arrayOf(PropTypes.string),
  retailerOptions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

RetailerSelect.defaultProps = {
  selectedRetailers: [],
  retailerOptions: [],
  onChange: () => {},
};

export default RetailerSelect;
