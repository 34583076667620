/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Card,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';
import WordSelector from './WordSelector';

import PromoTimeline from './PromoTimeline';
import ProductCard from './ProductCard';

const generateDates = (weeks = 12) => {
  const dates = [];
  const start = moment().startOf('week');
  for (let i = 0; i < weeks; i += 1) {
    const date = moment(start).subtract(i, 'weeks');
    dates.push(date);
  }
  dates.reverse();
  return dates;
};

const useStyles = makeStyles(() => ({
  imgWrapper: {
    width: 50,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  productImg: {
    maxWidth: 45,
    width: 'auto',
    alignSelf: 'center',
    height: 'auto',
    maxHeight: 45,
    padding: 2,
  },
}));


const StyledTableCell = withStyles(() => ({
  root: {
    fontSize: '18px',
    fontWeight: 500,
  },
}))(TableCell);

const PromotionsPanel = ({
  data, onPromoClick, skeletonRowCount, onWordSelect,
}) => {
  const largeScreenSize = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const timeline = generateDates();
  const classes = useStyles();

  const handlePromoClick = (promo) => { onPromoClick(promo); };

  const handleWordSelect = (word) => {
    onWordSelect(word);
  };
  if (largeScreenSize) {
    return (
      <Table size="small" stickyHeader style={{ tableLayout: 'fixed', marginTop: 15 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell component="th" style={{ width: '400px' }}>Product</StyledTableCell>
            {timeline.map((date, idx) => (
              <StyledTableCell key={`${date.format()}_${idx}`} component="th">{date.format('DD/MM')}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data && data.length > 0
              ? data.map((el, idx) => (
                <TableRow key={`${el.retailer}_${idx}`}>
                  <TableCell style={{ borderRight: '1px solid #eaeaea' }} component="th" scope="row">
                    <Box alignItems="center" display="flex">
                      <div className={classes.imgWrapper}>
                        <img
                          className={classes.productImg}
                          src={Array.isArray(el.imageURL) ? el.imageURL[0] : el.imageURL}
                          alt=""
                        />
                      </div>
                      <WordSelector text={el.name} onClick={handleWordSelect} fontSize="16px" />
                      <img
                        alt=""
                        width={24}
                        height={24}
                        src={`https://static.ef.uk.com/latest/images/retailer/${el.retailer.toLowerCase()}.png`}
                        style={{ marginLeft: 'auto' }}
                      />
                    </Box>
                  </TableCell>
                  <PromoTimeline
                    onClick={() => handlePromoClick(el)}
                    timeline={timeline}
                    promotions={el.promotions}
                  />
                </TableRow>
              ))
              : [...Array(skeletonRowCount || 50)].map((el, idx) => (
                <TableRow key={`skeleton_${idx}`}>
                  <TableCell style={{ borderRight: '1px solid #eaeaea' }} component="th" scope="row">
                    <Skeleton animation="wave" />
                  </TableCell>
                  <PromoTimeline timeline={timeline} />
                </TableRow>
              ))
          }
        </TableBody>
      </Table>
    );
  }
  return (
    <div style={{ padding: 10 }}>
      {

      data && data.length > 0
        ? data.map((el, idx) => (
          <ProductCard key={`${el.retailer}_${idx}`} product={el} productImage={Array.isArray(el.imageURL) ? el.imageURL[0] : el.imageURL} handlePromoClick={() => handlePromoClick(el)} handleWordSelect={handleWordSelect} />
        ))
        : [...Array(skeletonRowCount || 50)].map((el, i) => (
          <Card key={`skeleton-${i}`} style={{ minHeight: '18vh', margin: 25 }}>
            <Skeleton variant="rect" height="18vh" animation="wave" />
          </Card>
        ))
    }

    </div>
  );
};
PromotionsPanel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onPromoClick: PropTypes.func,
  onWordSelect: PropTypes.func,
  skeletonRowCount: PropTypes.number,
};

PromotionsPanel.defaultProps = {
  data: [],
  onWordSelect: () => { },
  onPromoClick: () => { },
  skeletonRowCount: 0,
};

export default PromotionsPanel;
