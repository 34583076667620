import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { Redirect, Link } from 'react-router-dom';

import ResetPasswordForm from '../forms/ResetPasswordForm';
import Layout from './Layout';
import FormWrapper from './FormWrapper';

const ResetPassword = () => {
  const [error, setError] = useState(false);
  const [complete, setComplete] = useState(false);

  const handleFailure = () => {
    setError(true);
  };

  const handleSuccess = () => {
    setComplete(true);
  };

  return (
    <FormWrapper>
      <Typography variant="h4" component="h2" align="center">
        Reset your password
      </Typography>
      {error && (
        <Typography>
          We could not complete your request, try sending the request again
          {' '}
          <Link to="/app/user/forgotPassword">here</Link>
          .
        </Typography>
      )}
      {!error
        && (
          <ResetPasswordForm onSuccess={handleSuccess} onFailure={handleFailure} />
        )}
      {complete && (
        <Redirect to="/app/user/signin" />
      )}
    </FormWrapper>
  );
};

export default Layout({ Content: ResetPassword });
