/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { context } from '../store';

const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#3f51b5',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#3f51b5',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ced4da',
      },
      '&:hover fieldset': {
        borderColor: '#3f51b5',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#3f51b5',
      },
    },
  },
})(TextField);


const uniqueTerms = (searchHistory = []) => {
  const terms = searchHistory.reduce(
    (acc, el) => [...acc, ...get(el, 'searchTerms', [])],
    [],
  );

  const termsSet = new Set();

  terms.forEach(termsSet.add, termsSet);

  return Array.from(termsSet);
};

const SearchBar = ({
  style, size, handleChange, terms,
}) => {
  const { state } = useContext(context);

  const searchHistory = get(state, 'userData.searchHistory', []);
  const suggestions = uniqueTerms(searchHistory);

  return (
    <div style={style} data-component="SearchBar">
      <Autocomplete
        id="search-bar"
        multiple
        size={size}
        options={suggestions}
        getOptionLabel={(option) => option}
        onChange={handleChange}
        value={terms}
        freeSolo
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant="outlined"
            disableUnderline
            placeholder="Search for products or a brand"
            InputProps={{
              ...params.InputProps,
              type: 'search',
              startAdornment: (
                <>
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

SearchBar.propTypes = {
  style: PropTypes.object,
  size: PropTypes.string,
  handleChange: PropTypes.func,
  terms: PropTypes.arrayOf(PropTypes.string),
};

SearchBar.defaultProps = {
  style: { margin: 'auto', width: '85%' },
  size: null,
  handleChange: () => {},
  terms: [],
};

export default SearchBar;
