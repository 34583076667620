import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import track from '../services/tracking';
import CookiePopUp from '../modals/CookiePopUp';

const CookieInformation = () => {
  const [showPopUp, setShowPopUp] = useState(false);

  const togglePopup = () => {
    setShowPopUp(!showPopUp);
  };

  const onDeclineCookies = () => {
    track('cookies_decline');
  };

  const cookiesSetUp = () => {
    // Cookies to be set with Cookies.set() import from react-cookie-consent;
    track('cookies_accept');
  };
  return (
    <div className="cookieBanner">
      <CookieConsent
        onAccept={cookiesSetUp}
        onDecline={onDeclineCookies}
        location="bottom"
        enableDeclineButton
        style={{ backgroundColor: '#f2f2f2', color: 'black' }}
        buttonStyle={{
          backgroundColor: '#233774', color: 'white', fontSize: '0.875rem', borderRadius: 5,
        }}
        declineButtonStyle={{
          backgroundColor: 'transparent', color: 'black', fontSize: '0.875rem', textDecorationLine: 'underline',
        }}
        flipButtons
      >
        This site uses cookies to enhance your experience.
        By continuing to browse the site, you are agreeing to our use of cookies.
        <button
          type="button"
          style={{
            backgroundColor: 'transparent', color: 'black', fontSize: '0.875rem', textDecorationLine: 'underline', borderColor: 'transparent',
          }}
          onClick={togglePopup}
        >
          Find out more.
        </button>
      </CookieConsent>
      <CookiePopUp onClose={togglePopup} isOpen={showPopUp} />
    </div>
  );
};

export default CookieInformation;
