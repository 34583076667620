// Provides an initialised firebase client
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';
import 'firebase/functions';

import config from '../config/firebase';

firebase.initializeApp(config);

// Provide devtools debugging convenience
window.firebase = firebase;

export const auth = firebase.auth();
export const database = firebase.database();
export const functions = firebase.app().functions('europe-west2');
export const getEmailCredential = firebase.auth.EmailAuthProvider.credential;

export const AuthProviders = {
  Google: new firebase.auth.GoogleAuthProvider(),
};


export default firebase;
