import React from 'react';
import PropTypes from 'prop-types';
import { GoogleLoginButton as GoogleButton } from 'react-social-login-buttons';
import UserService from '../services/UserService';
import linkedinConversion from '../services/linkedinConversion';

const GoogleLoginButton = ({
  onSignin,
  onError,
}) => {
  const logIn = async () => {
    try {
      const result = await UserService.signInWithAuthProvider('Google');
      onSignin(result);
      linkedinConversion();
    } catch (error) {
      onError(error);
    }
  };

  return (
    <GoogleButton onClick={logIn} />
  );
};

GoogleLoginButton.propTypes = {
  onSignin: PropTypes.func,
  onError: PropTypes.func,
};

GoogleLoginButton.defaultProps = {
  onSignin: () => {},
  onError: () => {},
};

export default GoogleLoginButton;
