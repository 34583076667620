import React from 'react';
import { Toolbar, Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import UserService from '../services/UserService';
import logo from '../resources/ef_promoNav.svg';
import FlashBar from './FlashBar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  headerIcon: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bolder',
    color: '#233774',
  },
  personIcon: {
    color: '#333088',
  },
}));

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserContainer = styled.div`
  justify-content: flex-end;
  display: flex;
`;

const Header = ({ user }) => {
  const classes = useStyles();

  const signOut = () => {
    UserService.signOut();
  };

  const showLogin = !user || user.isAnonymous;
  const showLogout = user && !user.isAnonymous;
  const showSignup = user && user.isAnonymous;

  return (
    <div>
      <FlashBar />
      <Grid container spacing={2}>
        <Toolbar className={classes.root}>
          <Grid item xs={6}>
            <Link to="/app">
              <LogoContainer>
                <img alt="" src={logo} style={{ width: '11em', zIndex: 10 }} />
              </LogoContainer>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <UserContainer>
              {showSignup && (
              <Button
                component={Link}
                to="/app/user/signup"
                className={classes.user}
              >
                Sign up
              </Button>
              )}
              {showLogin && (
              <Button
                component={Link}
                to="/app/user/signin"
                className={classes.user}
              >
                Log in
                <PersonIcon className={classes.personIcon} />
              </Button>
              )}
              {showLogout && (
              <>
                <Button
                  component={Link}
                  to="/app/user/settings"
                  className={classes.user}
                >
                  {user.displayName}
                  {' '}
                  <PersonIcon className={classes.personIcon} />
                </Button>
                <Button className={classes.logout} onClick={signOut}>
                  Log Out
                </Button>
              </>
              )}
            </UserContainer>
          </Grid>
        </Toolbar>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  user: PropTypes.shape({
    isAnonymous: PropTypes.bool,
    displayName: PropTypes.string,
  }),
};

Header.defaultProps = {
  user: null,
};

export default Header;
