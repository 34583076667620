import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect,
} from 'react-router-dom';
import FullStory from 'react-fullstory';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { get } from 'lodash';

import PromoCalendar from './views/PromoCalendar';
import ResetPassword from './views/ResetPassword';

import UserSettings from './views/UserSettings';
import SignIn from './views/SignIn';
import SignUp from './views/SignUp';
import ForgotPassword from './views/ForgotPassword';
import AuthSwitch from './components/AuthSwitch';
import GaurdModal from './components/GaurdModal';
import CalendarOnboarding from './components/CalendarOnboarding';
import ErrorBoundary from './components/ErrorBoundary';

// TODO: create a middleware / init wrapper for these
import Store from './store';
import AuthState from './components/AuthState';

import theme from './theme';
import CookieInformation from './components/CookieInformation';
import track from './services/tracking';

// TODO: move permissions logic somewhere else
const canUseApp = (state) => {
  const user = get(state, 'user', null);
  const isAnon = get(state, 'user.isAnonymous');
  const searches = get(state, 'userData.searchHistory.length', 0);

  if (user && !isAnon) {
    return true;
  }

  if (user && isAnon && searches <= 10) {
    return true;
  }

  track('sign_up_required');
  return false;
};

const Component = () => {
  track('page_view');
  return null;
};

const HistoryListener = withRouter(Component);

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <Store>
          <FullStory org="K4PMY" />
          <AuthState>
            <ThemeProvider theme={theme.efTheme}>
              <CssBaseline />
              <Router>
                <HistoryListener />
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/app" />
                  </Route>
                  <Route exact path="/app">
                    <GaurdModal check={canUseApp}>
                      <PromoCalendar />
                      { canUseApp && <CalendarOnboarding /> }
                    </GaurdModal>
                  </Route>
                  <Route exact path="/app/user/settings">
                    <AuthSwitch>
                      <UserSettings />
                    </AuthSwitch>
                  </Route>
                  <Route exact path="/app/user/forgotPassword">
                    <ForgotPassword />
                  </Route>
                  <Route exact path="/app/user/signin">
                    <SignIn />
                  </Route>
                  <Route exact path="/app/user/signup">
                    <SignUp />
                  </Route>
                  <Route exact path="/reset_password">
                    <ResetPassword />
                  </Route>
                </Switch>
              </Router>
              <CookieInformation />
            </ThemeProvider>
          </AuthState>
        </Store>
      </ErrorBoundary>
    </div>
  );
}

export default App;
