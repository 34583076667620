import React, { useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Slide,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import WeekTimeline from '../components/WeekTimeline';
import DateSelectDropdown from '../components/DateSelectDropdown';

/* eslint-disable react/jsx-props-no-spreading */
const Transition = React.forwardRef((props, ref) => (<Slide direction="up" ref={ref} {...props} />));

const parsePromotionDates = (product) => {
  const promos = product !== {} ? product.promotions : [];
  const dates = [];

  for (let i = 0; i < promos.length; i += 1) {
    const promo = promos[i];
    dates.push(
      {
        value: i,
        label: moment.utc(promo.timestamp).format('DD/MM/YY'),
      },
    );
  }
  return dates;
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(1),
    maxWidth: '90%',
  },
  zoomWrapper: {
    height: '100vh',
    width: '100vw',
  },
}));

const ArchiveModal = ({ open, onClose, product }) => {
  const classes = useStyles();
  const [mark, updateMark] = useState(0);
  const largeScreenSize = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const promotionDates = product.length < 1 ? null : parsePromotionDates(product);
  const screenshotURL = product.length < 1 ? null : product.promotions[mark].screenshotURL;

  if (largeScreenSize) {
    return (
      <Dialog fullWidth maxWidth="lg" open={open} TransitionComponent={Transition} onBackdropClick={onClose}>
        <DialogTitle>
          <Typography variant="h6">
            {product.name}
          </Typography>
          <IconButton className={classes.closeButton} color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers width={{ width: '70%' }}>
          <img
            alt=""
            src={screenshotURL}
            style={{
              width: '100%',
              height: 'auto',
              display: 'block',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Container>
            <WeekTimeline update={updateMark} promotionDates={promotionDates} />
          </Container>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      onBackdropClick={onClose}
    >
      <DialogTitle>
        <Typography className={classes.title} variant="h6">
          {product.name}
        </Typography>
        <IconButton className={classes.closeButton} color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers width={{ width: '70%' }}>
        <TransformWrapper
          options={{ limitToBounds: false }}
        >
          <TransformComponent>
            <div className={classes.zoomWrapper}>
              <img
                alt=""
                src={screenshotURL}
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'block',
                }}
              />
            </div>
          </TransformComponent>
        </TransformWrapper>
      </DialogContent>
      <DialogActions>
        <Container>
          <DateSelectDropdown promotionDateOptions={promotionDates} update={updateMark} />
        </Container>
      </DialogActions>
    </Dialog>
  );
};

const productType = PropTypes.shape({
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string,
      screenshotURL: PropTypes.string,
    }),
  ),
  name: PropTypes.string,
});

ArchiveModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  product: PropTypes.oneOfType([
    PropTypes.arrayOf(productType),
    productType,
  ]),
};

ArchiveModal.defaultProps = {
  open: false,
  onClose: () => { },
  product: {
    promotions: [],
    name: '',
  },
};

export default ArchiveModal;
