import React from 'react';
import { Fab, CircularProgress } from '@material-ui/core';
import { Sync } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    marginRight: 'auto',
    marginLeft: 'auto',
    position: 'relative',
  },
  progress: {
    color: '#233774',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  fab: {
    color: '#233774',
  },
  hidden: {
    display: 'none',
  },
}));

const LoadMoreButton = ({ show, loading, onClick }) => {
  const classes = useStyles();
  return (
    <div className={show ? '' : classes.hidden}>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Fab
            className={classes.fab}
            onClick={onClick}
            variant="extended"
            disabled={loading}
            size="medium"
          >
            <Sync />
            Load More
          </Fab>
          {loading && <CircularProgress size={25} className={classes.progress} />}
        </div>
      </div>
    </div>
  );
};

LoadMoreButton.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

LoadMoreButton.defaultProps = {
  show: true,
  loading: false,
};

export default LoadMoreButton;
