/* eslint-disable react/no-array-index-key */
import React from 'react';
import moment from 'moment';
import TableCell from '@material-ui/core/TableCell';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import PromoBar from './PromoBar';

const mergeCols = (cols) => {
  for (let i = 0; i < cols.length; i += 1) {
    const curr = cols[i];
    if (i > 0 && curr.type) {
      const prev = cols[i - 1];
      if (curr.type === prev.type) {
        curr.span += prev.span;
        prev.delete = true;
      }
    }
  }
};

const generateColumns = (timeline, promotions) => {
  const cols = timeline.map((date) => {
    // Find promo that lies in the week
    const promo = promotions
      ? promotions.find((promotion) => {
        const promodate = moment(promotion.timestamp);
        const start = moment(date);
        const end = moment(date).add(1, 'weeks');
        return promodate.isBetween(start, end);
      })
      : null;

    return {
      span: 1,
      type: promo ? promo.promotionType : null,
      text: promo ? promo.promotionText : null,
    };
  });

  mergeCols(cols);

  return cols.filter((item) => !item.delete);
};

const PromoTimeline = ({ timeline, promotions, onClick }) => {
  const cols = generateColumns(timeline, promotions);

  return (
    <>
      {cols.map((el, idx) => (
        <TableCell style={{ borderRight: '1px solid #eaeaea', padding: '2px 6px' }} colSpan={el.span} key={`cell_${el.type}_${el.span}_${idx}`}>
          { promotions ? <PromoBar type={el.type} text={el.text} onClick={onClick} key={`bar_${el.type}_${el.span}_${idx}`} /> : <Skeleton key={`sekeleton_${el.type}_${el.span}_${idx}`} /> }
        </TableCell>
      ))}
    </>
  );
};

PromoTimeline.propTypes = {
  timeline: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ]),
  ),
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      promotionType: PropTypes.oneOf([
        'MULTI_BUY',
        'UNCLASSIFIED',
        'PRICE_CUT',
      ]),
    }),
  ),
  onClick: PropTypes.func,
};

PromoTimeline.defaultProps = {
  timeline: [],
  promotions: [],
  onClick: () => {},
};

export default PromoTimeline;
