import React, { useState } from 'react';
import { Modal, Button, Fade } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import LoginForm from '../forms/LoginForm';
import SignUpForm from '../forms/SignUpForm';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 10,
    left: 10,
    zIndex: 97,
    background: 'rgba(63, 59, 150, .6)',
    backdropFilter: 'blur(3px)',
  },
  mobileModal: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    zIndex: 97,
    background: 'white',
    overflow: 'scroll',
  },
  authModalDiv: {
    background: 'white',
    borderRadius: 10,
    minWidth: 480,
    maxWidth: '25%',
  },
  mobileAuthModalDiv: {
    background: 'white',
    minWidth: '100%',
    overflow: 'scroll',
    display: 'flex',
    flexFlow: 'column nowrap',
    paddingBottom: 30,
  },
  signUpModalContent: {
    textAlign: 'center',
    padding: '0px 30px',
  },
  modalTitle: {
    marginBottom: 0,
    fontSize: '2.4em',
    fontWeight: 500,
  },
  modalSubTitle: {
    marginTop: 0,
    marginBottom: 35,
  },
  formdiv: {
    fontSize: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 5,
  },
  modalFooter: {
    padding: 10,
    backgroundColor: '#f3f3f3',
    marginTop: 20,
  },
  modalFooterContent: {
    textAlign: 'center',
    margin: 5,
    fontWeight: 600,
    fontSize: '0.9em',
    color: 'rgba(100, 100, 100, 0.96)',
  },
}));

const authActionConfig = {
  signup: {
    title: 'Welcome',
    subTitle: 'Create an account',
    submitButtonText: 'Continue',
    footerText: 'Already have an account?',
    footerLink: '/signin',
    footerLinkText: 'Log in',
    errorMessage: 'Password length should be more then 6',
  },
  signin: {
    title: 'Welcome Back',
    subTitle: 'Sign in to your account',
    submitButtonText: 'Log in',
    footerText: 'Do not have an account?',
    footerLink: '/signup',
    footerLinkText: 'Sign up',
    errorMessage: 'Incorrect username or password',
  },
};

const AuthenticationModal = ({ initialAction, open }) => {
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const classes = useStyles();
  const [disableBackdropClick] = useState(false);
  const [actionConfig, setActionConfig] = useState(
    authActionConfig[initialAction],
  );
  const [action, setAction] = useState(initialAction);

  const toggleMode = () => {
    const newMode = action === 'signup' ? 'signin' : 'signup';
    setAction(newMode);
    setActionConfig(authActionConfig[newMode]);
  };

  return (
    <Modal
      className={isLargeScreen ? classes.modal : classes.mobileModal}
      open={open}
      disableBackdropClick={disableBackdropClick}
    >
      <Fade timeout={600} in={open}>
        <div className={isLargeScreen ? classes.authModalDiv : classes.mobileAuthModalDiv}>
          <div className={classes.signUpModalContent}>
            <h2 className={classes.modalTitle}>{actionConfig.title}</h2>
            <p className={classes.modalSubTitle}>{actionConfig.subTitle}</p>

            {action === 'signup' && (
              <div>
                <SignUpForm />
              </div>
            )}
            {action === 'signin' && (
              <div>
                <LoginForm errorMessage={actionConfig.errorMessage} />
              </div>
            )}
          </div>
          <div className={classes.modalFooter}>
            <p className={classes.modalFooterContent}>
              {actionConfig.footerText}
              <Button onClick={toggleMode}>{actionConfig.footerLinkText}</Button>
            </p>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

AuthenticationModal.propTypes = {
  initialAction: PropTypes.string,
  open: PropTypes.bool,
};

AuthenticationModal.defaultProps = {
  initialAction: 'signup',
  open: false,
};

export default AuthenticationModal;
