import { onlyResolvesLast } from 'awesome-only-resolves-last-promise';
import config from '../config';
import track from './tracking';
import { functions } from './firebase';

const getPromotionsData = async ({
  searchTerms, retailers, size = config.api.promotions.tableResults, after,
}) => {
  // If there are no searchterms or retailers we will not trigger the api.
  if (searchTerms.length === 0 || retailers.length === 0) {
    return Promise.reject();
  }

  track('search', {
    terms: searchTerms,
    retailers,
    size,
    after,
  });

  const getPromotions = onlyResolvesLast(functions.httpsCallable('getPromotions'));

  return getPromotions({
    searchTerms, retailers: retailers.map((retailer) => retailer.toLowerCase()), size, after,
  });
};

export default getPromotionsData;
