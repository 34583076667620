import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import InputField from './InputField';

const ConfirmPassword = ({
  onChange,
}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState();
  const [passwordsEqual, setPasswordsEqual] = useState();

  const validatePassword = (pwd) => {
    setPasswordStrength(!(pwd.length < 6 && pwd.length > 0));

    if (confirmPassword.length > 0) {
      setPasswordsEqual(Object.is(pwd, confirmPassword));
    }
  };

  const validateConfirmPassword = (cPwd) => {
    setPasswordsEqual(Object.is(password, cPwd));
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    validatePassword(newPassword);
    setPassword(newPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    validateConfirmPassword(newConfirmPassword);
  };

  useEffect(() => {
    onChange({
      password,
      valid: !!(passwordStrength && passwordsEqual),
    });
  }, [password, confirmPassword, passwordStrength, passwordsEqual]);

  return (
    <Grid container spacing={2}>
      <Grid item xs="12">
        <InputField
          id="password"
          placeholder="Password"
          type="password"
          onChange={handlePasswordChange}
          value={password}
          error={passwordStrength === false}
          errorMessage="Too short"
        />
      </Grid>
      <Grid item xs="12">
        <InputField
          id="confirmPassword"
          placeholder="Confirm password"
          type="password"
          onChange={handleConfirmPasswordChange}
          value={confirmPassword}
          error={passwordsEqual === false}
          errorMessage="Passwords do not match"
        />
      </Grid>
    </Grid>
  );
};

ConfirmPassword.propTypes = {
  onChange: PropTypes.func,
};

ConfirmPassword.defaultProps = {
  onChange: () => {},
};

export default ConfirmPassword;
