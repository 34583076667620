import React, { useState } from 'react';
import { makeStyles, Button, Grid } from '@material-ui/core';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import UserService from '../services/UserService';
import ConfirmPassword from '../components/ConfirmPassword';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
  },
  success: {
    color: 'green',
  },
}));

const ResetPasswordForm = ({
  onSuccess,
  onFailure,
}) => {
  const style = useStyles();

  const [newPassword, setNewPassword] = useState();
  const [isValid, setIsValid] = useState(false);

  const handleConfirmChange = (change) => {
    if (change.valid) {
      setIsValid(true);
      setNewPassword(change.password);
    } else {
      setIsValid(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isValid) {
      const parsed = queryString.parse(window.location.search);

      try {
        await UserService.confirmPasswordReset(parsed.oobCode, newPassword);
        onSuccess();
      } catch (error) {
        onFailure();
      }
    }
  };

  return (
    <form data-testid="reset-password-form" className={style.form} autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing="2">
        <Grid item xs="12">
          <ConfirmPassword onChange={handleConfirmChange} />
        </Grid>
        <Grid item xs="12">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            disabled={!isValid}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

ResetPasswordForm.defaultProps = {
  onSuccess: () => {},
  onFailure: () => {},
};

export default ResetPasswordForm;
