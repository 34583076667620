import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { get } from 'lodash';
import SearchBar from './SearchBar';
import RetailerSelect from './RetailerSelect';
import UserService from '../services/UserService';
import { context } from '../store';

const useStyles = makeStyles(() => ({
  filterBarContainer: {
    minWidth: 300,
    maxWidth: 700,
    width: '100%',
    margin: '0 auto',
  },
}));

const Filters = () => {
  const classes = useStyles();
  const { state } = useContext(context);

  const availableRetailers = get(state, 'userData.retailers', []);
  const currentRetailers = get(state, 'userData.searchHistory[0].retailers', []);
  const currentSearchTerms = get(state, 'userData.searchHistory[0].searchTerms', []);

  const handleFilterChange = (e, values) => {
    const formattedTerms = values.length > 0 ? values.map((value) => (value.trim())) : values;
    const search = {
      retailers: currentRetailers,
      searchTerms: formattedTerms,
    };

    UserService.pushSearchHistory(search);
  };

  const handleRetailerChange = (values) => {
    const search = {
      retailers: values,
      searchTerms: currentSearchTerms,
    };

    UserService.pushSearchHistory(search);
  };

  return (
    <Grid container justify="center" alignItems="center" spacing={2}>
      <Grid item sm={6} xs={12}>
        <div className={classes.filterBarContainer}>
          <SearchBar
            handleChange={handleFilterChange}
            size="small"
            terms={currentSearchTerms}
          />
        </div>
      </Grid>
      <Grid item sm={6} xs={12}>
        <div className={classes.filterBarContainer}>
          <RetailerSelect
            onChange={handleRetailerChange}
            retailerOptions={availableRetailers}
            selectedRetailers={currentRetailers}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Filters;
