import React, { useState, useEffect, useContext } from 'react';
import { Container, Paper } from '@material-ui/core';
import { get } from 'lodash';
import Layout from './Layout';
import ArchiveModal from '../modals/ArchiveModal';
import NoData from '../components/NoData';
import PromotionsPanel from '../components/PromotionsPanel';
import LoadMoreButton from '../components/LoadMoreButton';
import ArchiveOnboarding from '../components/ArchiveOnboarding';
import Filters from '../components/Filters';
import UrlFilter from '../components/UrlFilter';
import getPromotionsData from '../services/promotion';
import UserService from '../services/UserService';
import { context } from '../store';
import config from '../config';
import useDebounce from '../hooks/useDebounce';

const tableResultSize = config.api.promotions.tableResults;

const PromoCalendar = () => {
  const { state } = useContext(context);

  const [promotionData, setPromotionData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [latestResultCount, setLatestResultCount] = useState(0);

  const searchTerms = get(state, 'userData.searchHistory[0].searchTerms', null);
  const debouncedSearchTerms = useDebounce(searchTerms, 500);
  const retailers = get(state, 'userData.searchHistory[0].retailers', null);
  const debouncedRetailers = useDebounce(retailers, 500);

  // Bunch of condtions to decide what to show the user
  const hasSearch = searchTerms !== null && searchTerms.length;
  const hasRetailers = retailers !== null && retailers.length;

  const hasData = promotionData && promotionData.length > 0;
  const showHint = !dataLoading && (!hasSearch || !hasRetailers);
  const showNoData = !dataLoading && hasSearch && hasRetailers && !hasData;
  const showData = !dataLoading && hasData;

  const handlePromoClick = (promo) => {
    setArchiveOpen(true);
    setSelectedProduct(promo);
  };

  const handleModalClose = () => {
    setArchiveOpen(false);
  };

  const loadData = async (clear = false, after = undefined) => {
    try {
      const { data } = await getPromotionsData({
        searchTerms,
        retailers,
        after,
      });

      if (clear) {
        setPromotionData(data);
      } else {
        setPromotionData([...promotionData, ...data]);
      }

      setLatestResultCount(data.length);
    } catch {
      setPromotionData([]);
    } finally {
      setDataLoading(false);
    }
  };

  const handleLoadMore = () => {
    const cursor = promotionData[promotionData.length - 1].productNo;
    loadData(false, cursor);
  };

  useEffect(() => {
    if (debouncedSearchTerms || debouncedRetailers) {
      setDataLoading(true);
      loadData(true);
    }
  }, [state.user, debouncedRetailers, debouncedSearchTerms]);

  const handleWordSelect = (word) => {
    const currRetailers = get(state, 'userData.searchHistory[0].retailers', []);
    const currTerms = get(state, 'userData.searchHistory[0].searchTerms', []);
    if (searchTerms.indexOf(word) === -1) {
      searchTerms.push(word);
      UserService.pushSearchHistory({
        searchTerms: currTerms,
        retailers: currRetailers,
      });
    }
  };

  return (
    <>
      <UrlFilter />
      <Filters />
      <Container maxWidth="xl" disableGutters>
        <Container
          maxWidth="xl"
          disableGutters
          style={{ paddingBottom: '125px' }}
        >
          <Paper style={{ marginTop: 20 }} elevation={1}>
            {showHint && (
              <NoData>
                Try searching for categories or brands you are interested in
              </NoData>
            )}
            {showNoData && (
              <NoData>
                It looks like you’re searching for something we haven’t yet
                added to our database.
              </NoData>
            )}
            {(showData || dataLoading) && (
              <div
                data-testid="loading-indicator"
                style={{
                  opacity: dataLoading ? 0.75 : 1.0,
                }}
              >
                <PromotionsPanel
                  data={promotionData}
                  onPromoClick={handlePromoClick}
                  onWordSelect={handleWordSelect}
                  skeletonRowCount={tableResultSize}
                />
                <LoadMoreButton
                  show={latestResultCount === tableResultSize}
                  loading={dataLoading}
                  onClick={handleLoadMore}
                />
              </div>
            )}
          </Paper>
        </Container>
        <ArchiveModal
          open={archiveOpen}
          onClose={handleModalClose}
          product={selectedProduct}
        />
        <ArchiveOnboarding archiveOpen={archiveOpen} />
      </Container>
    </>
  );
};

export default Layout({ Content: PromoCalendar });
